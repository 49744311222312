export const getBrowserInfo = () => {
  const userAgent = navigator.userAgent?.toLowerCase();

  if (userAgent.includes("samsungbrowser")) return "SamsungBrowser";
  if (userAgent.includes("edge")) return "Edge";
  if (userAgent.includes("chrome")) return "Chrome";
  if (userAgent.includes("firefox")) return "FireFox";
  if (userAgent.includes("safari")) return "Safari";
  if (userAgent.includes("opr")) return "Opera";
  return "Unknown";
};
